body main {
  font-weight: 300;
  font-size: 16px;
  padding-bottom: 50px;
}
body main article header {
  font-size: 20px;
  line-height: 1.4em;
}
body main article header h2 {
  font-size: 56px;
  font-weight: normal;
  line-height: 1.4em;
  margin: 14px 0 15px;
}
body main article #password {
  overflow: hidden;
  height: 68px;
  transition-property: height, opacity;
  transition-duration: 0.8s, 0.8s;
}
body main article #password.disabled {
  opacity: 0;
  height: 0;
}
body main article form button {
  margin-top: 32px;
}

@media (min-width: 1160px) {
  #ieWarning {
    /*color: white;*/
  }

  body {
    /*background-color: #090A0B;*/
    background-color: white;
  }
  body > header {
    background-color: #3E44FE;
  }
  body main {
    font-size: 16px;
    font-weight: 300;
    color: #090a0b;
    height: 557px;
    /*background-image: url('/images/background-left.png'), url('/images/background-right.png');*/
    /*background-image: url('/images/gradient.jpg');*/
    background-position: left top, right top;
    background-repeat: no-repeat;
    background-color: #FFF;
    margin: 0;
    position: relative;
    max-width: none;
  }
  body main article > * {
    position: relative;
    top: 122px;
    width: 364.17px;
  }
  body main article > *:last-child {
    margin-bottom: 0;
  }
  body main article header h2 {
    margin-top: 0;
  }
  body main article label {
    font-size: 12px;
    color: #6E828B;
  }
  body main article form {
    position: absolute;
    left: 50%;
    top: 147px;
    /*background: rgba(61,72,77,0.65);*/
    background: rgba(237, 240, 241, 0.65);
    width: 336px;
    box-sizing: border-box;
    margin-left: -168px;
    padding: 36px 40px 33px;
  }
  body main article form input {
    width: 256px;
    margin: 0 auto;
    /*color: white;*/
  }
  body main article form input:focus {
    width: 236px;
    color: #293033;
  }
  body main article form button {
    background: #3E44FE;
    border: 2px solid #1E2426;
    border-radius: 1px;
    font-size: 16px;
    color: #FFF;
    letter-spacing: 0.4px;
    width: 193px;
    height: 40px;
    margin-top: 32px;
  }
  body > footer #links {
    background-color: #E7EDF1;
  }
}