body {
    main {
        font-weight: 300;
        font-size: 16px;
        padding-bottom: 50px;

        article {
            header {
                font-size: 20px;
                line-height: 1.4em;

                h2 {
                    font-size: 56px;
                    font-weight: normal;
                    line-height: 1.4em;
                    margin: 14px 0 15px;
                }
            }

            #password {
                overflow: hidden;
                height: 68px;
                transition-property: height, opacity;
                transition-duration: .8s, .8s;
            }

            #password.disabled {
                opacity: 0;
                height: 0;
            }

            form {
                button {
                    margin-top: 32px;
                }
            }
        }
    }
}

@media (min-width:1160px) {
    #ieWarning {
        /*color: white;*/
    }
    body {
        /*background-color: #090A0B;*/
        background-color: white;

        > header {
            background-color: #3E44FE;
        }

        main {
            font-size: 16px;
            font-weight: 300;
            color: #090a0b;
            height: 557px;
            /*background-image: url('/images/background-left.png'), url('/images/background-right.png');*/
            /*background-image: url('/images/gradient.jpg');*/
            background-position: left top, right top;
            background-repeat: no-repeat;
            background-color: #FFF;
            margin: 0;
            position: relative;
            max-width: none;



            article {
                > * {
                    position: relative;
                    top: 122px;
                    width: 364.17px;
                }

                > *:last-child {
                    margin-bottom: 0;
                }


                header {
                    h2 {
                        margin-top: 0;
                    }
                }


                label {
                    font-size: 12px;
                    color: #6E828B;
                }

                form {
                    position: absolute;
                    left: 50%;
                    top: 147px;
                    /*background: rgba(61,72,77,0.65);*/
                    background: rgba(237, 240, 241, 0.65);
                    width: 336px;
                    box-sizing: border-box;
                    margin-left: -168px;
                    padding: 36px 40px 33px;

                    input {
                        width: 256px;
                        margin: 0 auto;
                        /*color: white;*/

                        &:focus {
                            width: 256px - 20px;
                            color: #293033;
                        }
                    }

                    button {
                        background: #3E44FE;
                        border: 2px solid #1E2426;
                        border-radius: 1px;
                        font-size: 16px;
                        color: #FFF;
                        letter-spacing: 0.4px;
                        width: 193px;
                        height: 40px;
                        margin-top: 32px;
                    }
                }
            }
        }

        > footer {
            #links {
                background-color: #E7EDF1;
            }
        }
    }
}
